import decode from "jwt-decode";
import Axios from "axios";
import {
  getLocationCookie,
  setLocationCookie
} from "../../../shared/helpers/location";
import ModalContent from "@/helpers/modals";

export const SESSION_COOKIE =
  process.env.VUE_APP_ENVIRONMENT === "staging" ? "ssession" : "session";

export function getCookies() {
  let cookie = document.cookie.split(";");
  let cookies = {};

  cookie.forEach(key => {
    let keyVal = key.trim().split("=");
    cookies[keyVal[0]] = keyVal[1];
  });

  return cookies;
}

export function getSessionCookie() {
  return getCookies()[SESSION_COOKIE];
}

export async function createSession(username, password, app, admin) {
  let data;
  let dataInfo;

  if (admin) {
    await Axios.post("/auth/authenticate", {
      name: username,
      password: "",
      admin: admin
    }).then(resp => {
      data = resp.data;
    });
  } else {
    await Axios.post("/auth/authenticate", {
      name: username,
      password: password
    })
      .then(resp => {
        data = resp.data;
      })
      .catch(({ response }) => {
        throw response.data.error;
      });
  }
  if (data.error) throw data.error;
  else {
    dataInfo = decode(data.token);
    if (dataInfo.activated) {
      setSessionCookie(data.token);

      let location = data.user.location;
      if (location && !getLocationCookie()) {
        if (location.split("-").length === 3) {
          location = location.replace(/\-/g, "|");
        }

        const parts = location.split("|");

        if (parts.length === 3) {
          setLocationCookie(parts[0], parts[1], parts[2]);
        }
      }

      app.$store.commit("setUser", data.user);
      app.$store.commit("setData", dataInfo);
    } else {
      // throw Error;
      app.$store.state.bus.$emit("alert", ModalContent.accountNotActivated);
      app.$bus.$emit("redirectVerification", data.user._id);
    }
  }
}

export async function adminUserSession(id, app) {
  let data;
  let dataInfo;
  await Axios.post("/auth/authenticate-admin-as-user", {
    id: id
  }).then(resp => {
    data = resp.data;
  });

  if (data.error) throw data.error;
  else {
    dataInfo = decode(data.token);
    setSessionCookie(data.token);

    app.$store.commit("setUser", data.user);
    app.$store.commit("setData", dataInfo);
  }
}

export function destroySession(app) {
  app.$axios.post("/auth/deauthenticate").then(() => {
    app.$store.dispatch("logout");

    switch (process.env.VUE_APP_ENVIRONMENT) {
      case "development":
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=localhost;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
      case "staging":
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=.staging.incredevent.com;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
      default:
        document.cookie = `${SESSION_COOKIE}=${getSessionCookie()};path=/;domain=.incredevent.com;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        break;
    }
  });
}

export function updateSession(app, callback) {
  let cookies = getCookies();
  let data = decode(cookies[SESSION_COOKIE]);
  let dataInfo;
  let respData;

  axios
    .post("/auth/update-session", {
      session: data.session_id,
      email: app.emailInput
    })
    .then(resp => {
      respData = resp.data;
      // get session data and store it in app data
      dataInfo = decode(respData.token);
      console.log(respData);
      console.log(dataInfo);
      if (dataInfo.activated) {
        // console.log(data);
        setSessionCookie(respData.token);

        // document.cookie = `session=${dataInfo}`;
        // console.log(dataInfo);
        app.$store.commit("setUser", respData.user);
        app.$store.commit("setData", dataInfo);
      } else {
        // throw Error;
        app.$store.state.bus.$emit("alert", ModalContent.accountNotActivated);
      }

      if (callback) callback();
    });
  console.log("destroy");
  console.log(app.$store.state.user.interests, "new store");
}

export function clearAuthToken() {}

export function isAuthenticated() {
  let cookies = getCookies();

  try {
    let decoded = decode(cookies[SESSION_COOKIE]);
    let authenticated = decoded.session_id;

    if (authenticated) {
      return true;
    } else {
      return false;
    }
  } catch (error) {}

  // return cookies[SESSION_COOKIE] && store.state.user.isAuthenticated;
}

export function setSessionCookie(session) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7);
  switch (process.env.VUE_APP_ENVIRONMENT) {
    case "development":
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=localhost;expires=${expirationDate.toUTCString()};`;
      break;
    case "staging":
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=.staging.incredevent.com;expires=${expirationDate.toUTCString()};`;
      break;
    default:
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=.incredevent.com;expires=${expirationDate.toUTCString()};`;
      break;
  }
}
