import Axios from "axios";
import Cookies from "universal-cookie";

export function getLocationCookie(req = null) {
  // if we have a req we're on the server, so we have to grab the cookie from the request headers
  // const cookies = req
  //   ? req.headers.cookie
  //     ? req.headers.cookie.split("; ")
  //     : [""]
  //   : document.cookie.split("; ");

  const cookies = new Cookies();
  let locationCookie = cookies.get("location");

  if (!locationCookie || locationCookie === "location=") {
    return null;
  }

  // handle legacy cookies that used dashes
  if (locationCookie.split("-").length === 3) {
    locationCookie = locationCookie.replace(/\-/g, "|");
  }

  if (locationCookie.split("|").length !== 3) {
    return null;
  }

  return locationCookie;
}

export function setLocationCookie(city, region, country, res = null) {
  let cookie;
  const cookies = new Cookies();
  cookies.remove("location", { path: "/" });

  switch (process.env.VUE_APP_ENVIRONMENT) {
    case "development":
      cookie = `location=${city}|${region}|${country}; domain=localhost; path=/`;
      break;
    case "staging":
      cookie = `location=${city}|${region}|${country}; domain=.staging.incredevent.com; path=/`;
      break;
    default:
      cookie = `location=${city}|${region}|${country}; domain=.incredevent.com; path=/`;
      break;
  }

  if (res) {
    res.setHeader("Set-Cookie", cookie);
  } else {
    document.cookie = cookie;
  }

  return cookie;
}

export async function getLocation({
  $axios = Axios,
  req = null,
  res = null,
} = {}) {
  // let locationCookie = getLocationCookie(req);
  let locationCookie = null;
  if (!locationCookie) {
    // let { data } = await $axios.post("/users/location");
    let data = [{ city: null }];

    if (!data[0].city || !data[0].region_name || !data[0].country_name) {
      // get user's coordinates
      data = await getUserLocationMaps();
      if (!data) {
        return null;
      }
    }
    setLocationCookie(data[0].city, data[0].region_name, data[0].country_name);

    return {
      city: data[0].city,
      region: data[0].region_name,
      country: data[0].country_name,
    };
  } else {
    const location = locationCookie.split("|");

    // Undefined Location Cookies Issue
    if (
      location[0] === "undefined" ||
      location[1] === "undefined" ||
      location[2] === "undefined"
    ) {
      const cookies = new Cookies();
      cookies.remove("location", { path: "/" });
      return {};
    }

    return {
      city: location[0],
      region: location[1],
      country: location[2],
    };
  }
}

function getCurrentPositionAsync() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
}

async function getUserLocationMaps() {
  try {
    const position = await getCurrentPositionAsync();
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    // Use Google Maps Geocoding API to get city and province
    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBS8YTbxBviNQGQJsPL-_aCBgpayzOPB20`;

    const response = await fetch(geocodingApiUrl);
    const data = await response.json();

    if (data.results.length > 0) {
      const addressComponents = data.results[0].address_components;
      let locationData = {};

      for (let i = 0; i < addressComponents.length; i++) {
        const types = addressComponents[i].types;
        if (types.includes("locality")) {
          locationData.city = addressComponents[i].long_name;
        } else if (types.includes("administrative_area_level_1")) {
          locationData.region_name = addressComponents[i].long_name;
        } else if (types.includes("country")) {
          locationData.country_name = addressComponents[i].long_name;
        }
      }
      return [locationData];
    } else {
      console.log("Location information not available.");
    }
  } catch (error) {
    console.error("Error fetching geocoding data:", error);
    throw error;
  }
}
export const fetchCoordinates = async (venueAddress) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBcexezrA_GnkA1uAOFi4KNpQGSzbP7nbM&address=${venueAddress}`
  ).then(async (response) => {
    return response
      .json()
      .then((data) => {
        let location = data.results[0].geometry.location;
        return {
          lat: location.lat,
          lng: location.lng,
        };
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
