import Vue from "vue";
import Axios from "axios";
import VueMeta from "vue-meta";
import * as Sentry from "@sentry/vue";
import VueFormulate from "@braid/vue-formulate";
import VMask from "v-mask";
import VCalendar from "v-calendar";
import VueQuillEditor from "vue-quill-editor";
import Toast, { POSITION } from "vue-toastification";
import { Integrations } from "@sentry/tracing";
import dayjs from "dayjs";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import { LoaderManager } from "@/helpers/loader";
import { NotificationManager } from "@/helpers/notifications";
import Card from "@/components/Card.vue";
import ClientOnly from "@/components/ClientOnly.vue";
import "@/styles/_formulate.less";
import "vue-toastification/dist/index.css";
import vGoogleTranslate from "v-google-translate";
import vuetify from "./plugins/vuetify";
import { convert } from "@/helpers/currencyConversion";

Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$bus = new Vue();
Vue.prototype.$loader = new LoaderManager(store);
Vue.prototype.$notifications = new NotificationManager();
Vue.use(vGoogleTranslate);

Axios.defaults.baseURL = process.env.VUE_APP_SERVICE_URL.slice(0, -1);

Vue.use(VueMeta, {
  keyName: "head"
});
Vue.use(VMask);
Vue.use(VCalendar, {});
Vue.use(Toast, {
  position: POSITION.BOTTOM_LEFT,
  maxToasts: 3,
  newestOnTop: true
});
Vue.use(VueFormulate, require("@/formulate.config.js").default);
Vue.use(VueQuillEditor);

Vue.component("Card", Card);
Vue.component("ClientOnly", ClientOnly);

Sentry.init({
  Vue,
  dsn:
    "https://314309d304674f299c11706cf0f96068@o1081988.ingest.sentry.io/6193348",
  environment: process.env.VUE_APP_ENVIRONMENT,
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //     tracingOrigins: ["localhost", "planners.incredevent.com", "planners.staging.incredevent.com", /^\//],
  //   }),
  // ],
  tracesSampleRate: 0.25,
  logErrors: true,
  enabled: process.env.NODE_ENV !== "development"
});

Vue.mixin({
  methods: {
    formatDate(date, format = "ddd, MMM DD, YYYY") {
      if (date.$date) {
        date = +date.$date.$numberLong;
      } else {
        date = +date;
      }

      return dayjs(date).format(format);
    },
    formatMoney(money, eventCurrencyCode = "cad") {
      // const cadOption = this.$store.state.cadCurrencyOption;
      // const usdOption = this.$store.state.usdCurrencyOption;
      // const currentStoreCurrency = this.$store.state.currency

      // if (cadOption === null && usdOption === null) {
      //   return;
      // }
      // let currency = null;

      // if (eventCurrencyCode === "usd") {
      //   currency = usdOption.find(
      //     x => x.label === currentStoreCurrency.name
      //   );
      // } else {
      //   currency = cadOption.find(
      //     x => x.label === currentStoreCurrency.name
      //   );
      // }

      return convert(1, parseFloat(money), "$");
    },
    createBuyersLink(link, whitelabel = null) {
      if (whitelabel && whitelabel.domainName) {
        if (!whitelabel.domainName.startsWith("http")) {
          whitelabel.domainName = "https://" + whitelabel.domainName;
        }

        switch (process.env.VUE_APP_ENVIRONMENT) {
          case "development":
            return `${whitelabel.domainName}/${link}`;
          case "staging":
            return `${whitelabel.domainName}/${link}`;
          default:
            return `${whitelabel.domainName}/${link}`;
        }
      } else {
        switch (process.env.VUE_APP_ENVIRONMENT) {
          case "development":
            return `http://localhost:3100/${link}`;
          case "staging":
            return `https://staging.incredevent.com/${link}`;
          default:
            return `https://incredevent.com/${link}`;
        }
      }
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
