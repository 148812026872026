<template>
  <div>
    <change-password-modal
      :active="passwordModal"
      v-on:dismiss="dismiss('passwordModal')"
      v-on:confirm="changePassword"
      ref="passwordChangeModals"
      :error="changePasswordErr"
    />
    <Home @createSession="newSession" />
  </div>
</template>

<style lang="less" scoped>
.home {
  #Content {
    .content-inner {
      .warning {
        width: 95%;
        margin-top: 20px;
        a {
          color: @colors[link];
        }
      }

      h1,
      h2,
      .subtitle {
        text-align: center;
      }

      .divider {
        margin-top: 0;
        margin-bottom: 0;
      }
      // end divider
      .form-cont {
        justify-content: space-between;

        form {
          // padding-top: 1em;
          position: relative;
          width: 95%;

          &.register {
            margin-left: 5%;

            &::before {
              content: "";
              position: absolute;
              left: calc(-5% + -10px);
              top: 0;
              height: 100%;
              border-right: 1px solid #5b5b5b;
            }
          }
          // end register

          .form-group {
            &.buttons-right {
              display: flex;
              justify-content: flex-end;
            }
          }

          .forgot-password-wrapper {
            position: absolute;
            right: 0px;
            z-index: 2;

            .forgot-password {
              color: @colors[link];
              font-weight: 500;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .coord-details {
        margin-bottom: 3em;
        background-color: #399c47;
        border: 2px solid #72d180;
        border-radius: 8px;
        box-shadow: 1px 0 10px 1px #0f0f0f8f, inset 0 1px 1px #0f0f0f8f;

        ul {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          height: 110px;

          li {
            width: 33%;
            color: #4a639e;

            span {
              color: #ffffff;
            }
          }
        }
      }
    }
    // end content-inner
  }
  // end Content
}
</style>

<script>
import ForgotModal from "@/components/modals/ForgotModal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { createSession, adminUserSession } from "../helpers/auth.js";
import Vue from "vue";
import Home from "@/components/LoginRegister.vue";
import ModalContent from "@/helpers/modals";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import ChangePasswordModal from "@/components/modals/ChangePasswordModal";
import { getThemeCookie } from "../../../shared/helpers/theme";

export default {
  name: "home",
  components: {
    DiyoboInput,
    DiyoboButton,
    ForgotModal,
    ProcessingModal,
    Home,
    ChangePasswordModal
  },
  head() {
    return {
      title:
        "The Best Event Management Software • Planner Features • INCREDEVENT",
      meta: [
        {
          name: "description",
          content: `***$0.80 Ticket Fees for 12 months*** INCREDEVENT is offering a covid-reflief program for event planners and promoters. Calculate your savings here!`
        },
        {
          name: "keywords",
          content: "Events, Tickets, Directory, Promotion"
        },
        {
          name: "author",
          content: "INCREDEVENT"
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0"
        }
      ]
    };
  },
  data() {
    return {
      loginErrors: {
        usernameError: "",
        passwordError: ""
      },
      passwordModal: false,
      changePasswordErr: "",

      usernameInput: "",
      passwordInput: "",
      usernameError: false,
      passwordError: false,
      loginErrorMessages: [],
      loginErrorsToCorrect: [],
      registerErrors: {
        regEmailError: "",
        regPasswordError: "",
        regConfirmPasswordError: "",
        regFirstNameError: "",
        regLastNameError: ""
      },
      regUserExists: false,
      regUserExistsMsg: "",
      regPassWeak: false,
      regPassMsg: "",
      regEmailInput: "",
      regPasswordInput: "",
      regConfirmPasswordInput: "",
      regEmailError: false,
      regPasswordError: false,
      regConfirmPasswordError: false,
      regFirstName: "",
      regFirstNameError: false,
      regLastName: "",
      regLastNameError: false,
      registerErrorMessages: [],
      registerErrorsToCorrect: [],
      redirectModal: false,
      redirectModalMsg: "",
      redirectModalLink: "",
      bus: new Vue(),
      newCoordinator: false,
      fmodal: false,
      fmodalE: null,
      processing: false
    };
  },
  computed: {
    session_id: {
      get() {
        return this.$store.state.session_id;
      },
      set(id) {
        this.$store.state.session_id = id;
        return id;
      }
    },
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    async newSession(email, password, parent) {
      try {
        await createSession(email, password, parent);

        const theme = getThemeCookie();
        if (theme === "system") {
          this.$store.commit("setTheme", this.user.theme);
        }

        let sessionId = this.$store.state.session_id;
        if (sessionId) {
          const notificationsUrl =
            process.env.VUE_APP_NOTIFICATIONS_SOCKET +
            "?session_id=" +
            this.$store.state.session_id;

          this.$notifications.connect(notificationsUrl);
          this.$notifications.init("planners");

          if (
            this.user.diyobo &&
            this.user.first_name === "Your" &&
            this.user.last_name === "Name"
          ) {
            this.$root.$emit("show-diyobo-name-update-modal");
          }

          if (this.newCoordinator) {
            this.$router.push("brand-settings");
          } else {
            this.$router.push("/dashboard");
          }
        }
      } catch (error) {
        if (error.type === "diyobo") {
          this.$bus.$emit("diyobo", email);
        } else if (
          error == "Your account has been locked, please contact support."
        ) {
          this.$bus.$emit("alert", ModalContent.accountLocked);
        } else {
          this.$bus.$emit("alert", ModalContent.incorrectLogin);
        }
      }
    },
    async changePassword(password, confirm) {
      await this.$axios
        .post("/account/change-password", {
          password: password,
          confirmPassword: confirm,
          key: this.$route.query.forgot_password_key.trim()
        })
        .then(({ data }) => {
          this.passwordModal = false;

          const content = ModalContent.changePasswordSuccess;
          this.$bus.$emit("alert", {
            title: content.title,
            msg: content.msg,
            confirm: "Okay"
          });
        })
        .catch(({ response }) => {
          this.changePasswordErr = response.data.error.message;
        });
    },
    async createUser() {
      let data;
      await this.$axios
        .post("/users/create", {
          first: this.regFirstName,
          last: this.regLastName,
          email: this.regEmailInput,
          password: this.regPasswordInput,
          website: null,
          phone: null,
          coordinator: true
        })
        .then(resp => {
          data = resp.data;
        });
      if (await data.error) throw await data.error;

      this.$store.state.bus.$emit("alert", ModalContent.registerAfter);
    },
    dismiss(val) {
      this[val] = false;
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },
    forgotConfirm(email) {
      this.$axios
        .post("/account/forgot-password", { email })
        .then(response => {
          this.fmodal = false;
          this.$store.state.bus.$emit(
            "alert",
            ModalContent.forgotPasswordAfter
          );
        })
        .catch(e => {
          this.fmodalE = e;
        });
    },
    async superUserLogin(id) {
      try {
        await adminUserSession(id, this);
        let sessionId = this.$store.state.session_id;
        if (sessionId) {
          const notificationsUrl =
            process.env.VUE_APP_NOTIFICATIONS_SOCKET +
            "?session_id=" +
            this.$store.state.session_id;

          this.$notifications.connect(notificationsUrl);
          this.$notifications.init("buyers");
        }

        this.$router.push("/dashboard");
      } catch (error) {
        this.$root.$emit("alert", ModalContent.incorrectLogin);
      }
    }
  },
  async mounted() {
    let routeKey = this.$route.query.activation_key;
    if (routeKey) {
      let activation_key;
      if (routeKey.includes("^")) {
        activation_key = this.$route.query.activation_key.split("^");
      } else {
        activation_key = this.$route.query.activation_key.split("%5E");
      }

      await this.$axios
        .post("/account/activate", {
          key: activation_key[1]
        })
        .then(resp => {
          // console.log(resp);
          let data = resp.data;
          if (resp.status == 200) {
            // if (!data[2]) {
            this.newCoordinator = true;
            const content = ModalContent.accountActivated;
            this.$parent.$refs.alert.open({
              title: content.title,
              msg: content.msg,
              confirm: content.confirm
              // onConfirm: () => {
              //   this.$bus.$emit("login");
              // },
            });
            // }
          } else {
            const content = ModalContent.accountActivationFailed;
            this.$parent.$refs.alert.open({
              // title: content.title,
              msg: content.msg
              // confirm: content.confirm,
              // onConfirm: () => {
              //   this.$bus.$emit("login");
              // },
            });
            // this.$store.state.bus.$emit(
            //   "alert",
            //   ModalContent.accountActivationFailed
            // );
          }
          // console.log(data);
          // const content = ModalContent.accountActivated;
          // this.$parent.$refs.alert.open({
          //   title: content.title,
          //   msg: content.msg,
          //   confirm: content.confirm,
          //   // onConfirm: () => {
          //   //   this.$bus.$emit("login");
          //   // },
          // });
        });
    }

    if (this.$route.query.forgot_password_key) {
      let fpk = this.$route.query.forgot_password_key.trim();
      if (fpk) {
        this.passwordModal = true;
      }
    }

    let admin = this.$route.query.login_admin;
    if (admin) {
      await this.$axios
        .post("/auth/admin-as-user", {
          key: admin
        })
        .then(resp => {
          if (resp.data.success) {
            this.superUserLogin(resp.data.user._id);
          }
        });
    }
  }
};
</script>
